import clsx from "clsx";
import { H2, Paragraph } from "~/components/ui/typography";
import { ConditionalLink } from "./ui/link";
import { BlockContent } from "~/components/block-content";
import { ArrowLink } from "./ui/arrow-button";
import type { ImageProps } from "~/components/ui/image";
import { Image } from "~/components/ui/image";
import type { Cta } from "~/types";
import type { SimplePortableText } from "~/types/sanity-schema";

export type Logo = {
	logoImage: ImageProps;
	title?: string;
	ctaLink?: string;
	logoHeight?: number;
};

interface LogoItemProps {
	logo: Logo;
	isGrayscale?: boolean;
	isShowLogoCaption?: boolean;
	isHideItemLink?: boolean;
	imageLoading?: ImageProps["loading"];
}

export function LogoItem({
	logo,
	isGrayscale,
	isShowLogoCaption = false,
	isHideItemLink = false,
	imageLoading = "lazy",
}: LogoItemProps) {
	const { logoImage, title, ctaLink, logoHeight = 45 } = logo;
	const hasLink = Boolean(ctaLink) && Boolean(!isHideItemLink);

	return (
		<figure className="text-center">
			<ConditionalLink
				condition={hasLink}
				to={ctaLink || ""}
				trackingText={title}
				trackingPosition="logo"
				className={clsx({
					"opacity-70 grayscale transition-all hover:opacity-100 hover:grayscale-0":
						isGrayscale,
				})}
			>
				{logoImage ? (
					<div className="flex min-h-0 items-center justify-center sm:min-h-[70px]">
						<Image
							loading={imageLoading}
							style={{ height: `${logoHeight}px` }}
							{...logoImage}
						/>
					</div>
				) : null}
			</ConditionalLink>
			{title && isShowLogoCaption ? (
				<figcaption className="pt-3 sm:pt-0">
					<Paragraph size="body-small">{title}</Paragraph>
				</figcaption>
			) : null}
		</figure>
	);
}

interface LogoListProps {
	align?: "center" | "left";
	variant?: "logo" | "logo-side";
	tagline?: string;
	subtitle?: SimplePortableText;
	title?: string;
	isGrayscale?: boolean;
	isShowLogoCaption?: boolean;
	isHideItemLink?: boolean;
	logoItems: Logo[];
	primaryCTA?: Cta;
}

export function LogoList({
	align = "center",
	variant = "logo",
	tagline,
	title,
	subtitle,
	isGrayscale = false,
	isShowLogoCaption = false,
	isHideItemLink = false,
	logoItems,
	primaryCTA,
}: LogoListProps) {
	const ctaContent = (
		<>
			{primaryCTA && primaryCTA.title && primaryCTA.url ? (
				<div
					className={clsx("mt-7 flex md:justify-center", {
						"lg:justify-start": variant === "logo-side",
					})}
				>
					<ArrowLink to={primaryCTA.url} trackingPosition="logo">
						{primaryCTA.title}
					</ArrowLink>
				</div>
			) : null}
		</>
	);
	return (
		<div
			className={clsx({
				"flex flex-col items-center justify-center gap-7 text-center lg:flex-row lg:text-left":
					variant === "logo-side",
			})}
		>
			{title || subtitle || tagline ? (
				<div
					className={clsx("text-center", {
						"max-w-content": variant === "logo",
						"basis-1/3": variant === "logo-side",
						"mx-auto": align === "center",
						"md:text-left": align === "left",
					})}
				>
					{tagline ? (
						<Paragraph
							size="overline"
							className="mb-5"
							color="tagline"
						>
							{tagline}
						</Paragraph>
					) : null}
					{title ? <H2>{title}</H2> : null}
					{subtitle && variant != "logo-side" ? (
						<div className="mt-5">
							<BlockContent value={subtitle} />
						</div>
					) : null}
					{variant === "logo-side" ? ctaContent : null}
				</div>
			) : null}
			{logoItems.length > 0 ? (
				<div
					className={clsx(
						"flex flex-wrap items-center justify-center gap-9 sm:flex-row",
						{
							"lg:justify-between": logoItems.length >= 5,
							"mt-7": variant === "logo",
							"md:justify-start": align === "left",
						}
					)}
				>
					{logoItems.map((logo, index) => (
						<LogoItem
							key={index}
							logo={logo}
							isGrayscale={isGrayscale}
							isShowLogoCaption={isShowLogoCaption}
							isHideItemLink={isHideItemLink}
						/>
					))}
				</div>
			) : null}
			{variant === "logo" ? ctaContent : null}
		</div>
	);
}
